import React, { ReactNode } from 'react';
import ReactDOMServer from 'react-dom/server';
import { MetaformComponent, FieldValue, IconName } from "metaform-react"; 
import { Icon, Loader, Dimmer, Container, Sticky, Message, Ref } from "semantic-ui-react";
import JsPDF from "jspdf";
import metaform from "./form.json";
import { MetaformSection, MetaformField, MetaformFieldType, MetaformFieldOption } from 'metaform-react/dist/models/api';

/**
 * Component props
 */
interface Props {

}

/**
 * Component state
 */
interface State {
  form: any,
  values: { [key: string]: FieldValue },
  loading: boolean,
  saving: boolean,
  saved: boolean,
  pdfData?: string
}

/**
 * App component
 */
class App extends React.Component<Props, State> {

  private containerRef = React.createRef();
  private formRef = React.createRef<HTMLFormElement>();

  /**
   * Constructor
   * 
   * @param props component props 
   */
  constructor(props: Props) {
    super(props);
    
    this.state = {
      form: {},
      values: {},
      loading: true,
      saving: false,
      saved: false
    };
  }

  /**
   * Component did-mount life cycle method
   */
  public componentDidMount = async () => {
    this.setState({
      loading: true
    });

    this.setState({
      form: metaform,
      loading: false
    });
  }

  /**
   * Component render
   */
  public render() {
    if (this.state.loading) {
      return (
        <div style={{ height: "100vh" }}>
          <Dimmer inverted active>
            <Loader>Lataa...</Loader>
          </Dimmer>
        </div>
      );
    }

    return (
      <div className="App">
        <Container>
          <Ref innerRef={ this.containerRef }>
            <div>
              { this.renderMetaform() }
              { this.renderSaved() }
              { this.renderSaving() }
              { this.renderForm() }
            </div>
          </Ref>
        </Container>
      </div>
    );
  }

  /**
   * Renders the data form
   */
  private renderForm = () => {
    return (
      <div style={{ opacity: 0 }}>
        <form method="post" action="https://viestilomake.suomi.fi/form" ref={ this.formRef } onSubmit={ this.onDataFormSubmit }>
          <input type="hidden" name="ViranomaisTunnus" id="ViranomaisTunnus" value={ process.env.REACT_APP_AUTHORITATIVE_ID } />
          <input type="hidden" name="PalveluTunnus" id="PalveluTunnus" value={ process.env.REACT_APP_SERVICE_ID } />
          <input type="hidden" name="ViranomaisenEmail" id="ViranomaisenEmail" value={ process.env.REACT_APP_RECIPIENT_EMAIL } />
          <input type="hidden" name="Nimeke" id="Nimeke" value={ process.env.REACT_APP_TITLE } />
          <input type="hidden" name="Kuvausteksti" id="Kuvausteksti" value={ process.env.REACT_APP_DESCRIPTION } />
          <input type="hidden" name="TiedostoNimi_1" id="TiedostoNimi_1" value="palaute.pdf" />
          <input type="hidden" name="TiedostoSisalto_1" id="TiedostoSisalto_1" value={ this.state.pdfData }/>
          <input type="hidden" name="TiedostoMuoto_1" id="TiedostoMuoto_1" value="application/pdf" />
          <input type="hidden" name="TiedostoPiilotettu_1" id="TiedostoPiilotettu_1" value="0" />
        </form>
      </div>
    );
  }

  /**
   * Renders PDF
   */
  private renderPdfContents = () => {
    return (
      <div>
        { this.renderPdfTitle() }
        { this.renderPdfSections() }
      </div>
    );
  }

  /**
   * Renders PDF title
   */
  private renderPdfTitle = () => {
    const metaform = this.state.form;
    if (!metaform.title) {
      return null;
    }

    return (
      <h1 style={{ color: "#3162af" }}>
        { metaform.title }
      </h1>
    );
  }
  
  /**
   * Renders PDF sections
   */
  private renderPdfSections = () => {
    const metaform = this.state.form;

    return (
      <div>
        {
          (metaform.sections || []).map((section: MetaformSection) => {
            return (
              <div>
                <h2> { section.title } </h2>
                <div>
                  { this.renderPdfFields(section) }
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }

  /**
   * Renders PDF fields
   */
  private renderPdfFields = (section: MetaformSection) => {
    return (
      <div>
        {
          (section.fields || []).map((field) => {
            return (
              <div>
                <b> { field.title } </b>
                <br/>
                { this.getFieldDisplayValue(field) }
                <br/>
                <br/>
              </div>
            );
          })
        }
      </div>
    );
  }

  /**
   * Renders the metaform
   */
  private renderMetaform = () => {
    return ( 
      <MetaformComponent renderIcon={ this.renderIcon } formReadOnly={ this.state.saving || this.state.saved } form={ this.state.form } getFieldValue={ this.getFieldValue } setFieldValue={ this.setFieldValue } onSubmit={ this.onSubmit }/>
    );
  }

  /**
   * Renders saved message  
   */
  private renderSaved = () => {
    if (!this.state.saved) {
      return null;
    }

    return (
      <Sticky context={ this.containerRef }>
        <Message positive style={{ marginBottom: "10px" }}>
          <Message.Header>Vastaus tallennettu onnistuneesti</Message.Header>
          <p>Voit sulkea nyt tämän sivun</p>
        </Message>
      </Sticky>
    );
  }

  /**
   * Renders saved message
   */
  private renderSaving = () => {
    if (!this.state.saving) {
      return null;
    }

    return (
      <Sticky context={ this.containerRef }>
        <Message color="olive" style={{ marginBottom: "10px" }}>
          <Message.Header>Vastausta tallennetaan</Message.Header>
          <p>
            <Icon loading name='spinner' /> Odota hetki...
          </p>
        </Message>
      </Sticky>
    );
  }
  
  /**
   * Returns field value from form value store
   * 
   * @param fieldName field's name
   * @returns field value
   */
  private getFieldValue = (fieldName: string): FieldValue => {
    return this.state.values[fieldName];
  }

  /**
   * Returns display value for field
   * 
   * @returns display value
   */
  private getFieldDisplayValue = (field: MetaformField): string | null => {
    const value = this.getFieldValue(field.name!);
    if (!this.state.form || !field || !value) {
      return null;
    }

    switch (field.type) {
      case MetaformFieldType.Radio:
        const option = (field.options || []).find((option: MetaformFieldOption) => {
          return option.name === value;
        });

        return option ? option.text : null;
    }

    return String(value);
  }

  /**
   * Sets a value into form value store
   * 
   * @param fieldName field's name
   * @param fieldValue field's value
   */
  private setFieldValue = (fieldName: string, fieldValue: FieldValue) => {
    const values = { ...this.state.values };
    values[fieldName] = fieldValue;

    this.setState({
      values: values
    });
  }

  /**
   * Renders an icon
   * 
   * @param icon name of icon
   * @param key unique key
   * @returns icon
   */
  private renderIcon = (icon: IconName, key: string): ReactNode => {
    switch (icon) {
      case "dot-circle-o":
        return <Icon name="dot circle outline" key={ key }/>
      case "circle-o":
        return <Icon name="dot circle" key={ key }/>
    }

    return <div/>
  }

  /**
   * Renders PDF
   */
  private createPdf = () => {
    const jsPdf = new JsPDF();
    jsPdf.fromHTML(ReactDOMServer.renderToStaticMarkup(this.renderPdfContents()), 10, 10);    
    return btoa(jsPdf.output());
  }

  /**
   * Handler for form submit event
   */
  private onSubmit = async () => {
    this.setState({
      saving: true,
      pdfData: this.createPdf()
    }, () => {
      this.formRef.current!.submit();
    });
  }

  /**
   * Handler for data form submit
   */
  private onDataFormSubmit = () => {
    this.setState({
      saving: false,
      saved: true
    });
  }
}

export default App;
